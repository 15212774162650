<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">{{ title }}</p>
        </header>
        <section class="modal-card-body">
            <b-field>
                <b-select placeholder="Currency"
                          v-model="currency">
                    <option value="hkd">HK$</option>
                </b-select>
                <b-input type="number" placeholder="0.00"
                         v-model="price"></b-input>
            </b-field>
        </section>
        <footer class="modal-card-foot">
            <button class="button" type="button"
                    v-on:click="$parent.close()">
                Cancel
            </button>
            <button class="button is-primary"
                    v-bind:disabled="!allowSave"
                    v-bind:class="{ 'is-loading': loading }"
                    v-on:click="save">
                Save
            </button>
        </footer>
    </div>
</template>

<script>
    export default {
        name: 'ModalSetPrice',
        props: {
            title: {
                type: String,
                required: true
            },
            prices: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                loading: false,

                currency: 'hkd',
                price: null
            }
        },
        computed: {
            allowSave() {
                return this.currency && this.price
            }
        },
        methods: {
            save() {
                this.prices = [{}]
                this.prices[0][this.currency] = this.price
                console.log(this.prices)
                this.$emit('update:prices', this.prices)
                this.$parent.close()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-card-foot {
        justify-content: flex-end;
    }
</style>
