<template>
    <section class="product-details">
        <tool-bar v-bind:lang.sync="lang"
                  enable-translate
                  v-bind:translate="enableTcToSc">
            {{ mode }} a product
            <template slot="buttons">
                <div class="level-item">
                    <div class="select">
                        <select>
                            <option>Online Shop</option>
                        </select>
                    </div>
                </div>
                <div class="level-item">
                    <button class="button"
                            v-if="mode === 'Create'"
                            v-bind:class="{ 'is-loading': loading }"
                            v-bind:disabled="!allowSave"
                            v-on:click="save">
                        <span class="icon is-small">
                            <font-awesome-icon icon="save"></font-awesome-icon>
                        </span>
                        <span>Save</span>
                    </button>
                    <button class="button"
                            v-if="mode === 'Update'"
                            v-bind:class="{ 'is-loading': loading }"
                            v-bind:disabled="!allowSave"
                            v-on:click="update">
                        <span class="icon is-small">
                            <font-awesome-icon icon="save"></font-awesome-icon>
                        </span>
                        <span>Update</span>
                    </button>
                </div>
            </template>
        </tool-bar>

        <div class="banner position-relative pb-4">
            <button class="button edit"
                    v-on:click="setCover"
                    v-on:mouseover="revealImageElement"
                    v-on:mouseout="revealImageElement">
                <span class="icon is-small">
                    <font-awesome-icon icon="image"></font-awesome-icon>
                </span>
                <span>Change Cover</span>
            </button>
            <section class="categories">
                <b-select placeholder="Product Series"
                          v-model="series">
                    <option v-for="option in productSeries"
                            v-bind:value="option._id"
                            v-bind:key="option._id">
                        {{ option.title[lang] }}
                    </option>
                </b-select>
                <div class="separator"> /</div>
                <b-select placeholder="Product Category"
                          v-model="category">
                    <option v-for="option in categories"
                            v-bind:value="option._id"
                            v-bind:key="option._id">
                        {{ option.name[lang] }}
                    </option>
                </b-select>
            </section>
            <background-filter color="transparent"
                               v-bind:src="cover">
            </background-filter>
            <div class="container py-5">
                <div class="row h-100">
                    <div class="col-md-5 pr-md-4 pb-4 pb-md-0">
                        <div class="product-image position-relative text-center h-100">
                            <button class="button edit"
                                    v-on:click="setImage"
                                    v-on:mouseover="revealImageElement"
                                    v-on:mouseout="revealImageElement">
                                <span class="icon is-small">
                                    <font-awesome-icon icon="image"></font-awesome-icon>
                                </span>
                                <span>Change Image</span>
                            </button>
                            <img class="img-responsive" v-bind:src="images[0]">
                            <img class="mirror position-absolute" v-bind:src="images[0]">
                        </div>
                    </div>
                    <div class="col-md-7 d-flex flex-column justify-content-center">
                        <medium-editor class="text-center text-md-left editable"
                                       custom-tag="h1"
                                       v-bind:style="{ 'color': titleColor.rgba ? `rgba(${ titleColor.rgba.r }, ${ titleColor.rgba.g }, ${ titleColor.rgba.b }, ${ titleColor.rgba.a }) !important` : `rgba(${ titleColor.r }, ${ titleColor.g }, ${ titleColor.b }, ${ titleColor.a }) !important` }"
                                       v-bind:text="title[lang]"
                                       v-bind:options="Object.assign({ placeholder: { text: 'Title', } }, options)"
                                       v-on:edit="processTitleEditOperation">
                        </medium-editor>

                        <medium-editor class="text-center text-md-left editable"
                                       custom-tag="p"
                                       v-bind:style="{ 'color': titleColor.rgba ? `rgba(${ titleColor.rgba.r }, ${ titleColor.rgba.g }, ${ titleColor.rgba.b }, ${ titleColor.rgba.a }) !important` : `rgba(${ titleColor.r }, ${ titleColor.g }, ${ titleColor.b }, ${ titleColor.a }) !important` }"
                                       v-bind:text="subtitle[lang]"
                                       v-bind:options="Object.assign({ placeholder: { text: 'Subtitle', } }, options)"
                                       v-on:edit="processSubtitleEditOperation">
                        </medium-editor>

                        <h2 class="text-center text-md-left mb-2 editable"
                            v-bind:style="{ 'color': titleColor.rgba ? `rgba(${ titleColor.rgba.r }, ${ titleColor.rgba.g }, ${ titleColor.rgba.b }, ${ titleColor.rgba.a }) !important` : `rgba(${ titleColor.r }, ${ titleColor.g }, ${ titleColor.b }, ${ titleColor.a }) !important` }"
                            v-on:click="setPrice(prices[0])">
                            <small>HK$</small>{{ prices[0].hkd | currency("", 0) }}
                        </h2>

                        <div class="position-relative mb-4">
                            <button class="button button-color-picker"
                                    v-on:click="isTitleColorModalActive = true">
                                <span class="icon is-small">
                                    <font-awesome-icon icon="palette"></font-awesome-icon>
                                </span>
                                <span>Set title color</span>
                            </button>
                            <color-picker class="position-absolute color-picker"
                                          v-bind:class="{ 'show': isTitleColorModalActive }"
                                          v-model="titleColor">
                                <template slot="footer">
                                    <div class="button-color-default">
                                        <button class="button is-small"
                                                v-on:click="resetTitleColorToDefault">
                                            Reset to Default
                                        </button>
                                    </div>
                                </template>
                            </color-picker>
                        </div>

                        <p class="text-center text-md-left">
                            <button class="btn btn-lg btn-dark btn-no-round-corner"
                                    v-bind:style="{ 'color': buttonTextColor.rgba ? `rgba(${ buttonTextColor.rgba.r }, ${ buttonTextColor.rgba.g }, ${ buttonTextColor.rgba.b }, ${ buttonTextColor.rgba.a }) !important` : `rgba(${ buttonTextColor.r }, ${ buttonTextColor.g }, ${ buttonTextColor.b }, ${ buttonTextColor.a }) !important`, 'background-color': buttonBgColor.rgba ? `rgba(${ buttonBgColor.rgba.r }, ${ buttonBgColor.rgba.g }, ${ buttonBgColor.rgba.b }, ${ buttonBgColor.rgba.a }) !important` : `rgba(${ buttonBgColor.r }, ${ buttonBgColor.g }, ${ buttonBgColor.b }, ${ buttonBgColor.a }) !important`, 'border-color': buttonBgColor.rgba ? `rgba(${ buttonBgColor.rgba.r }, ${ buttonBgColor.rgba.g }, ${ buttonBgColor.rgba.b }, ${ buttonBgColor.rgba.a }) !important` : `rgba(${ buttonBgColor.r }, ${ buttonBgColor.g }, ${ buttonBgColor.b }, ${ buttonBgColor.a }) !important` }">
                                {{ translations.addToCart[lang] }}
                            </button>
                        </p>

                        <div class="position-relative mb-4">
                            <button class="button button-color-picker mr-2"
                                    v-on:click="isButtonBgColorModalActive = true">
                                <span class="icon is-small">
                                    <font-awesome-icon icon="palette"></font-awesome-icon>
                                </span>
                                <span>Set button background color</span>
                            </button>
                            <color-picker class="position-absolute color-picker"
                                          v-bind:class="{ 'show': isButtonBgColorModalActive }"
                                          v-model="buttonBgColor">
                                <template slot="footer">
                                    <div class="button-color-default">
                                        <button class="button is-small"
                                                v-on:click="resetButtonBgColorToDefault">
                                            Reset to Default
                                        </button>
                                    </div>
                                </template>
                            </color-picker>

                            <button class="button button-color-picker"
                                    v-on:click="isButtonTextColorModalActive = true">
                                <span class="icon is-small">
                                    <font-awesome-icon icon="palette"></font-awesome-icon>
                                </span>
                                <span>Set button text color</span>
                            </button>
                            <color-picker class="position-absolute color-picker"
                                          v-bind:class="{ 'show': isButtonTextColorModalActive }"
                                          v-model="buttonTextColor">
                                <template slot="footer">
                                    <div class="button-color-default">
                                        <button class="button is-small"
                                                v-on:click="resetButtonTextColorToDefault">
                                            Reset to Default
                                        </button>
                                    </div>
                                </template>
                            </color-picker>
                        </div>

                        <div class="d-flex align-items-baseline justify-content-center justify-content-md-start mb-2">
                            <span class="mr-3"
                                  v-bind:style="{ 'color': shareColor.rgba ? `rgba(${ shareColor.rgba.r }, ${ shareColor.rgba.g }, ${ shareColor.rgba.b }, ${ shareColor.rgba.a }) !important` : `rgba(${ shareColor.r }, ${ shareColor.g }, ${ shareColor.b }, ${ shareColor.a }) !important` }">
                                {{ translations.shareTo[lang] }}
                            </span>
                            <div class="social-network"
                                 v-bind:style="{ 'color': shareColor.rgba ? `rgba(${ shareColor.rgba.r }, ${ shareColor.rgba.g }, ${ shareColor.rgba.b }, ${ shareColor.rgba.a }) !important` : `rgba(${ shareColor.r }, ${ shareColor.g }, ${ shareColor.b }, ${ shareColor.a }) !important` }">
                                <font-awesome-icon class="mr-3"
                                                   v-bind:icon="['fab', 'facebook-square']">
                                </font-awesome-icon>
                                <font-awesome-icon class="mr-3"
                                                   v-bind:icon="['fab', 'line']">
                                </font-awesome-icon>
                                <font-awesome-icon class="mr-3"
                                                   v-bind:icon="['fab', 'twitter']">
                                </font-awesome-icon>
                                <font-awesome-icon class="mr-3"
                                                   v-bind:icon="['fab', 'weibo']">
                                </font-awesome-icon>
                                <font-awesome-icon class="mr-3"
                                                   v-bind:icon="['fab', 'whatsapp']">
                                </font-awesome-icon>
                            </div>
                        </div>

                        <div class="position-relative mb-4">
                            <button class="button button-color-picker"
                                    v-on:click="isShareButtonsColorModalActive = true">
                                <span class="icon is-small">
                                    <font-awesome-icon icon="palette"></font-awesome-icon>
                                </span>
                                <span>Set share button color</span>
                            </button>
                            <color-picker class="position-absolute color-picker"
                                          v-bind:class="{ 'show': isShareButtonsColorModalActive }"
                                          v-model="shareColor">
                                <template slot="footer">
                                    <div class="button-color-default">
                                        <button class="button is-small"
                                                v-on:click="resetShareButtonsColorToDefault">
                                            Reset to Default
                                        </button>
                                    </div>
                                </template>
                            </color-picker>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="description py-5 mb-4">
            <div class="container">
                <div class="mb-5">
                    <h2 class="text-center text-md-left">
                        {{ translations.description[lang] }}
                    </h2>
                    <vue-editor id="editor-description"
                                style="background-color: white; "
                                useCustomImageHandler
                                v-bind:customModules="customQuillModules"
                                v-bind:editorOptions="editorOptions"
                                v-on:imageAdded="contentImageHandler"
                                v-model="description[lang]">
                    </vue-editor>
                </div>
                <div class="mb-5">
                    <h2 class="text-center text-md-left">
                        {{ translations.usage[lang] }}
                    </h2>
                    <vue-editor id="editor-usage"
                                style="background-color: white; "
                                useCustomImageHandler
                                v-bind:customModules="customQuillModules"
                                v-bind:editorOptions="editorOptions"
                                v-on:imageAdded="contentImageHandler"
                                v-model="usage[lang]">
                    </vue-editor>
                    <!-- <medium-editor class="text-center text-md-left editable"
                                   custom-tag="p"
                                   v-bind:text="usage[lang]"
                                   v-bind:options="Object.assign({ placeholder: { text: 'Usage', } }, options)"
                                   v-on:edit="processUsageEditOperation">
                    </medium-editor> -->
                </div>

                <div class="mb-5">
                    <h2 class="text-center text-md-left">
                        Downloadable Attachments
                    </h2>
                    <div>
                        <table class="table is-bordered is-striped is-hoverable is-fullwidth">
                            <thead>
                            <tr>
                                <th>Added on</th>
                                <th>Attachment</th>
                                <th>Size</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    28/11月/2022 11:46 上午
                                </td>
                                <td>
                                    marketing_reference.pdf
                                </td>
                                <td>
                                    1.32MiB
                                </td>
                                <td>
                                    <button class="button is-small is-text is-warning">
                                        <font-awesome-icon icon="edit"></font-awesome-icon>
                                    </button>
                                    <button class="button is-small is-text is-danger">
                                        <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <b-field>
                            <b-upload v-model="dropFiles"
                                      multiple
                                      drag-drop
                                      expanded>
                                <section class="section">
                                    <div class="content has-text-centered">
                                        <p>
                                            <b-icon icon="upload" size="is-large"></b-icon>
                                        </p>
                                        <p>Drop your attachments here or click to upload</p>
                                    </div>
                                </section>
                            </b-upload>
                        </b-field>
                    </div>
                </div>
            </div>
        </div>

        <div class="messages">
            <article class="message">
                <div class="message-header">
                    <p>The following options and variables are internal to the system and only shown in the
                        dashboard. </p>
                </div>
                <div class="message-body">
                    <div class="label">Product Weight</div>
                    <b-field>
                        <b-input type="number" placeholder="0.00"
                                 v-model="weight">
                        </b-input>
                        <b-select placeholder="Weight Unit"
                                  v-model="weightUnit">
                            <option value="g">g</option>
                            <option value="kg">kg</option>
                        </b-select>
                    </b-field>

                    <div class="label">Product Cost (Optional)</div>
                    <b-field>
                        <b-select placeholder="Currency"
                                  v-model="costCurrency">
                            <option value="hkd">$</option>
                        </b-select>
                        <b-input type="number" placeholder="0.00"
                                 v-model="cost">
                        </b-input>
                    </b-field>
                </div>
            </article>
        </div>

        <b-modal scroll="keep" has-modal-card
                 v-bind:active.sync="isPriceModalActive">
            <modal-set-price title="Configure Product Price"
                             v-bind:prices.sync="prices"></modal-set-price>
        </b-modal>

        <b-modal scroll="keep" has-modal-card
                 v-bind:active.sync="isImageModalsActive.cover">
            <modal-set-image allow-video
                             v-bind:file.sync="coverFile"
                             v-bind:video.sync="videoCover">
                Configure Product Cover
            </modal-set-image>
        </b-modal>

        <b-modal scroll="keep" has-modal-card
                 v-bind:active.sync="isImageModalsActive.image">
            <modal-set-image v-bind:file.sync="imageFiles"
                             multiple="false">
                Configure Product Image
            </modal-set-image>
        </b-modal>
    </section>
</template>

<script>
import * as sanitizeHtml from "sanitize-html";
import tinycolor from "tinycolor2";

import {VueEditor} from "vue2-editor";
import VideoResize from "quill-video-resize-module";

import editor from "vue2-medium-editor";

import Chrome from "../vue-color/components/Chrome";

import BackgroundFilter from "../BackgroundFilter";
import ModalSetPrice from "../modals/ModalSetPrice";
import ModalSetImage from "../modals/ModalSetImage";
import ToolBar from "../layouts/ToolBar";

const opencc = require("node-opencc")
export default {
    name: "ProductDetails",
    components: {
        ToolBar,
        ModalSetImage,
        ModalSetPrice,
        BackgroundFilter,
        VueEditor,
        "medium-editor": editor,
        "color-picker": Chrome
    },
    data() {
        return {
            loading: false,
            edited: false,

            lang: "en",
            enableTcToSc: true,
            translations: {
                "addToCart": {
                    "en": "Add to cart",
                    "zh-hk": "加入購物車",
                    "zh-cn": "加入购物车"
                },
                "description": {
                    "en": "Description",
                    "zh-hk": "產品介紹",
                    "zh-cn": "产品介绍"
                },
                "usage": {
                    "en": "Usage",
                    "zh-hk": "使用方法",
                    "zh-cn": "使用方法"
                },
                "shareTo": {
                    "en": "Share to: ",
                    "zh-hk": "分享至：",
                    "zh-cn": "分享至："
                }
            },

            customQuillModules: [{
                alias: "VideoResize",
                module: VideoResize
            } /* {
                    alias: "imageDrop",
                    module: ImageDrop
                }, {
                    alias: "imageResize",
                    module: ImageResize
                } */],

            editorOptions: {
                modules: {
                    // imageDrop: true,
                    // imageResize: {},
                    VideoResize: {
                        modules: ["Resize", "DisplaySize", "Toolbar"]
                    }
                }
            },

            mode: "Create",
            options: {
                toolbar: {
                    buttons: ["bold", "italic", "underline", "strikethrough"]
                }
            },

            isTitleColorModalActive: false,
            isButtonBgColorModalActive: false,
            isButtonTextColorModalActive: false,
            isShareButtonsColorModalActive: false,

            isPriceModalActive: false,
            isImageModalsActive: {
                cover: false,
                image: false
            },

            title: {
                "en": "",
                "zh-hk": "",
                "zh-cn": ""
            },

            subtitle: {
                "en": "",
                "zh-hk": "",
                "zh-cn": ""
            },

            titleColor: {
                r: 113,
                g: 113,
                b: 113,
                a: 1
            },

            buttonBgColor: {
                r: 113,
                g: 113,
                b: 113,
                a: 1
            },

            buttonTextColor: {
                r: 255,
                g: 255,
                b: 255,
                a: 1
            },

            shareColor: {
                r: 113,
                g: 113,
                b: 113,
                a: 1
            },

            category: "",
            series: "",

            images: [],
            imageFiles: [],
            cover: "",
            coverFile: null,
            videoCover: {},

            description: {
                "en": "",
                "zh-hk": "",
                "zh-cn": ""
            },
            usage: {
                "en": "",
                "zh-hk": "",
                "zh-cn": ""
            },

            prices: [{
                hkd: null
            }],

            cost: null,
            costCurrency: "hkd",

            weight: null,
            weightUnit: "g"
        }
    },
    computed: {
        categories() {
            return this.$store.state.shop.categories
                .filter(
                    ({ type }) => type === "type"
                );
        },

        productSeries() {
            return this.$store.state.shop.series.map(
                ({ _id, title, }) => ({
                    _id,
                    title: {
                        "en": sanitizeHtml(
                            title["en"],
                            {
                                allowedTags: []
                            }
                        ),
                        "zh-hk": sanitizeHtml(
                            title["zh-hk"],
                            {
                                allowedTags: []
                            }
                        ),
                        "zh-cn": sanitizeHtml(
                            title["zh-cn"],
                            {
                                allowedTags: []
                            }
                        )
                    }
                })
            )
        },

        allowSave() {
            return this.series &&
                this.category &&
                this.title["en"] &&
                this.title["zh-hk"] &&
                this.title["zh-cn"] &&
                this.subtitle["en"] &&
                this.subtitle["zh-hk"] &&
                this.subtitle["zh-cn"] &&
                this.description["en"] &&
                this.description["zh-hk"] &&
                this.description["zh-cn"] &&
                this.usage["en"] &&
                this.usage["zh-hk"] &&
                this.usage["zh-cn"] &&
                this.cover &&
                this.images.length > 0 &&
                this.weight &&
                this.weight > 0 &&
                this.prices[0].hkd
        }
    },
    methods: {
        setCover() {
            this.isImageModalsActive.cover = true
        },

        setImage() {
            this.isImageModalsActive.image = true
        },

        setPrice(price) {
            this.isPriceModalActive = true
        },

        resetTitleColorToDefault() {
            let color = tinycolor({
                r: 113,
                g: 113,
                b: 113,
                a: 1
            })
            this.titleColor = {
                hex: color.toHex8String(),
                hsl: color.toHsl(),
                hsv: color.toHsv(),
                rgba: color.toRgb(),
                a: color.toRgb().a
            }
        },

        resetButtonBgColorToDefault() {
            let color = tinycolor({
                r: 113,
                g: 113,
                b: 113,
                a: 1
            })
            this.buttonBgColor = {
                hex: color.toHex8String(),
                hsl: color.toHsl(),
                hsv: color.toHsv(),
                rgba: color.toRgb(),
                a: color.toRgb().a
            }
        },

        resetButtonTextColorToDefault() {
            let color = tinycolor({
                r: 255,
                g: 255,
                b: 255,
                a: 1
            })
            this.buttonTextColor = {
                hex: color.toHex8String(),
                hsl: color.toHsl(),
                hsv: color.toHsv(),
                rgba: color.toRgb(),
                a: color.toRgb().a
            }
        },

        resetShareButtonsColorToDefault() {
            let color = tinycolor({
                r: 113,
                g: 113,
                b: 113,
                a: 1
            })
            this.shareColor = {
                hex: color.toHex8String(),
                hsl: color.toHsl(),
                hsv: color.toHsv(),
                rgba: color.toRgb(),
                a: color.toRgb().a
            }
        },

        revealImageElement(evt) {
            evt.currentTarget.parentElement.classList.toggle("active")
        },

        processTitleEditOperation(operation) {
            this.title[this.lang] = operation.api.origElements.innerHTML
            if (this.enableTcToSc) {
                if (this.lang === "zh-hk") {
                    this.title["zh-cn"] = opencc.traditionalToSimplified(operation.api.origElements.innerHTML)
                }
            }
        },

        processSubtitleEditOperation(operation) {
            this.subtitle[this.lang] = operation.api.origElements.innerHTML
            if (this.enableTcToSc) {
                if (this.lang === "zh-hk") {
                    this.subtitle["zh-cn"] = opencc.traditionalToSimplified(operation.api.origElements.innerHTML)
                }
            }
        },

        contentImageHandler(file, Editor, cursorLocation, resetUploader) {
            console.log(file, cursorLocation)

            if (file instanceof File) {
                /* this.$store.dispatch("mall/addTestimonialContentImage", file)
                            this.images.push(file); */

                let reader = new FileReader()
                reader.addEventListener("load", () => {
                    let res = Editor.insertEmbed(cursorLocation, "image", reader.result)
                    console.log(cursorLocation)
                    console.log(res)
                }, false)
                reader.readAsDataURL(file)
            }
            resetUploader()
        },

        async save() {
            let temp = {
                series: this.series,
                category: this.category,
                title: this.title,
                subtitle: this.subtitle,
                description: this.description,
                usage: this.usage,
                cover: this.coverFile,
                images: this.imageFiles,
                prices: this.prices,
                weight: {}
            }

            temp.weight[this.weightUnit] = this.weight

            if (this.cost) {
                if (!temp.cost || typeof temp.cost !== "object") {
                    temp.cost = {}
                }
                temp.cost[this.costCurrency] = this.cost
            }

            if (this.titleColor.rgba) {
                temp.titleColor = this.titleColor.rgba
            }

            if (this.buttonBgColor.rgba) {
                temp.buttonColors = {
                    background: this.buttonBgColor.rgba
                }
            }

            if (this.buttonTextColor.rgba) {
                if (temp.buttonColors && typeof temp.buttonColors === "object") {
                    temp.buttonColors.text = this.buttonTextColor.rgba
                } else {
                    temp.buttonColors = {
                        text: this.buttonTextColor.rgba
                    }
                }
            }

            if (this.shareColor.rgba) {
                temp.shareColor = this.shareColor.rgba
            }

            if (this.videoCover) {
                temp.videoCover = this.videoCover
            }

            this.loading = true
            try {
                await this.$store.dispatch("shop/addProduct", temp)
            } catch (e) {
                console.log(e)
                this.loading = false
                return
            }
            this.loading = false
            this.$router.push({
                name: "products"
            })
        },

        async update() {
            let temp = {
                _id: this._id,
                series: this.series,
                category: this.category,
                title: this.title,
                subtitle: this.subtitle,
                description: this.description,
                usage: this.usage,
                prices: this.prices,
                weight: {}
            }

            console.log(this.prices)

            temp.weight[this.weightUnit] = this.weight

            if (this.cost) {
                if (!temp.cost || typeof temp.cost !== "object") {
                    temp.cost = {}
                }
                temp.cost[this.costCurrency] = this.cost
            }

            if (this.coverFile) {
                temp.cover = this.coverFile
            }

            if (this.imageFiles.length > 0) {
                temp.images = this.imageFiles
            }

            if (this.titleColor.rgba) {
                temp.titleColor = this.titleColor.rgba
            }

            if (this.buttonBgColor.rgba) {
                temp.buttonColors = {
                    background: this.buttonBgColor.rgba
                }
            }

            if (this.buttonTextColor.rgba) {
                if (temp.buttonColors && typeof temp.buttonColors === "object") {
                    temp.buttonColors.text = this.buttonTextColor.rgba
                } else {
                    temp.buttonColors = {
                        text: this.buttonTextColor.rgba
                    }
                }
            }

            if (this.shareColor.rgba) {
                temp.shareColor = this.shareColor.rgba
            }

            if (this.videoCover) {
                temp.videoCover = this.videoCover
            }

            this.loading = true
            try {
                await this.$store.dispatch("shop/updateProduct", temp)
            } catch (e) {
                console.log(e)
                this.loading = false
                return
            }
            this.loading = false
            this.$router.push({
                name: "products"
            })
        }
    },
    mounted () {
        const product = this.$store.state.shop.products
            .find(
                ({ _id, }) => _id === this.$route.params.productId
            );

        if (product) {
            this.mode = "Update";

            this._id = product._id;
            this.title = product.title;
            this.subtitle = product.subtitle;
            this.category = product.category;
            this.series = product.series;
            this.description = product.description;
            this.usage = product.usage;
            this.prices = product.prices;

            this.images = product.images.map(
                image => `${this.$config.apiUrl}/${this.$config.apiVersion}/shop/product/image/${image}`
            );
            this.cover = `${this.$config.apiUrl}/${this.$config.apiVersion}/shop/product/image/${product.cover}`;

            this.weightUnit = Object.keys(product.weight)[0];
            this.weight = product.weight[this.weightUnit];

            if (product.cost) {
                this.costCurrency = Object.keys(product.cost)[0];
                this.cost = product.cost[this.costCurrency];
            }

            if (product.titleColor) {
                this.titleColor = product.titleColor;
            }

            if (product.buttonColors) {
                if (product.buttonColors.background) {
                    this.buttonBgColor = product.buttonColors.background;
                }
                if (product.buttonColors.text) {
                    this.buttonTextColor = product.buttonColors.text;
                }
            }

            if (product.shareColor) {
                this.shareColor = product.shareColor;
            }

            if (product.videoCover) {
                this.videoCover = product.videoCover;
            }
        }

        document
            .getElementById("app")
            .addEventListener(
                "click",
                (evt) => {
                    if (
                        !evt.target.matches(".color-picker *") &&
                        !evt.target.matches(".button-color-picker") &&
                        !evt.target.matches(".button-color-picker *")
                    ) {
                        this.isTitleColorModalActive = false;
                        this.isButtonBgColorModalActive = false;
                        this.isButtonTextColorModalActive = false;
                        this.isShareButtonsColorModalActive = false;
                    }
                }
            );
    },
    watch: {
        "$route.params.productId" (newVal) {
            const product = this.$store.state.shop.products
                .find(
                    ({ _id }) => _id === newVal
                );

            if (product) {
                this.mode = "Update";

                this._id = product._id
                this.title = product.title
                this.subtitle = product.subtitle
                this.category = product.category
                this.series = product.series
                this.description = product.description
                this.usage = product.usage
                this.prices = product.prices

                this.images = product.images.map(
                    image => `${this.$config.apiUrl}/${this.$config.apiVersion}/shop/product/image/${image}`
                )
                this.cover = `${this.$config.apiUrl}/${this.$config.apiVersion}/shop/product/image/${product.cover}`

                this.weightUnit = Object.keys(product.weight)[0]
                this.weight = product.weight[this.weightUnit]

                if (product.cost) {
                    this.costCurrency = Object.keys(product.cost)[0]
                    this.cost = product.cost[this.costCurrency]
                }

                if (product.titleColor) {
                    this.titleColor = product.titleColor
                }

                if (product.buttonColors) {
                    if (product.buttonColors.background) {
                        this.buttonBgColor = product.buttonColors.background
                    }
                    if (product.buttonColors.text) {
                        this.buttonTextColor = product.buttonColors.text
                    }
                }

                if (product.shareColor) {
                    this.shareColor = product.shareColor
                }

                if (product.videoCover) {
                    this.videoCover = product.videoCover
                }
            }
        },

        "$store.state.shop.products": {
            handler (newVal) {
                if (newVal.length > 0) {
                    let product = newVal.find(product => product._id === this.$route.params.productId)

                    if (product) {
                        this.mode = "Update"

                        this._id = product._id
                        this.title = product.title
                        this.subtitle = product.subtitle
                        this.category = product.category
                        this.series = product.series
                        this.description = product.description
                        this.usage = product.usage
                        this.prices = product.prices

                        this.images = product.images.map(
                            image => `${this.$config.apiUrl}/${this.$config.apiVersion}/shop/product/image/${image}`
                        )
                        this.cover = `${this.$config.apiUrl}/${this.$config.apiVersion}/shop/product/image/${product.cover}`

                        this.weightUnit = Object.keys(product.weight)[0]
                        this.weight = product.weight[this.weightUnit]

                        if (product.cost) {
                            this.costCurrency = Object.keys(product.cost)[0]
                            this.cost = product.cost[this.costCurrency]
                        }

                        if (product.titleColor) {
                            this.titleColor = product.titleColor
                        }

                        if (product.buttonColors) {
                            if (product.buttonColors.background) {
                                this.buttonBgColor = product.buttonColors.background
                            }
                            if (product.buttonColors.text) {
                                this.buttonTextColor = product.buttonColors.text
                            }
                        }

                        if (product.shareColor) {
                            this.shareColor = product.shareColor
                        }

                        if (product.videoCover) {
                            this.videoCover = product.videoCover
                        }
                    }
                }
            },
            deep: true
        },

        "coverFile"(newVal) {
            if (newVal instanceof File) {
                let reader = new FileReader()
                reader.addEventListener("load", () => {
                    this.cover = reader.result
                }, false)
                reader.readAsDataURL(newVal)
            }
        },

        "imageFiles"(newVal) {
            if (newVal.length && newVal.length > 0) {
                let temp = []
                newVal.forEach(
                    file => {
                        if (file instanceof File) {
                            let reader = new FileReader()
                            reader.addEventListener("load", () => {
                                temp.push(reader.result)
                            }, false)
                            reader.readAsDataURL(file)
                        }
                    }
                )
                this.images = temp
            }
        },

        "description": {
            handler: function (newVal) {
                if (this.enableTcToSc) {
                    if (this.lang === "zh-hk") {
                        this.description["zh-cn"] = opencc.traditionalToSimplified(newVal["zh-hk"])
                    }
                }
            },
            deep: true
        },

        "usage": {
            handler: function (newVal) {
                if (this.enableTcToSc) {
                    if (this.lang === "zh-hk") {
                        this.usage["zh-cn"] = opencc.traditionalToSimplified(newVal["zh-hk"])
                    }
                }
            },
            deep: true
        }
    }
}
</script>

<style lang="scss" scoped>
$theme-colors: (
    "dark": rgba(113, 113, 113, 1)
);
@import "~bootstrap/scss/bootstrap";

.product-details {
    .editable {
        position: relative;
        border-bottom: dashed 2px #8c67ef;

        &:focus {
            background-color: rgba(255, 255, 255, 1);
            border-radius: 4px;
            border: solid 2px rgba(125, 125, 125, 1);
            outline: none;
        }

        &:after {
            position: absolute;
            bottom: -24px;
            right: 0;
            content: "Click to edit";
            font-size: 14px;
            font-weight: normal;
            padding: 3px;
        }
    }

    button {
        &.edit {
            position: absolute;
            z-index: 8;
        }
    }

    .banner {
        position: relative;
        padding-top: 62px;
        min-height: 40vh;

        &.active {
            border: dashed 2px #ff1f4b;
        }

        & > button.edit {
            bottom: 5px;
            right: 5px;
        }

        & > .categories {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;

            display: flex;
            justify-content: center;
            align-items: center;

            margin-top: 5px;

            z-index: 8;

            & > .separator {
                margin: 0 15px;
                font-size: 20px;
                font-weight: bold;
            }
        }

        .img-responsive {
            height: auto;
            max-height: 400px;
            max-width: 100%;
        }

        .product-image {
            &.active {
                border: dashed 2px #ff1f4b;
            }

            & > button.edit {
                bottom: 0;
                right: 0;
            }

            & > .mirror {
                top: 100%;
                left: 0;
                right: 0;

                height: 100%;
                width: inherit;
                margin: 0 auto;

                transform: scaleY(-1);
                -webkit-mask-image: -webkit-gradient(linear, left 85%, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 1)));
            }
        }
    }

    .social-network {
        color: rgba(113, 113, 113, 1);
        font-size: 28px;
        margin-bottom: 7.5px;
    }

    .messages {
        padding: 2rem;

        & > .message {
            background-color: rgba(255, 255, 255, 1);
            box-shadow: 0 2px 15px rgba(0, 0, 0, 0.5);

            & > .message-header {
                & > p {
                    margin-bottom: 0;
                }
            }

            & > .message-body {

            }
        }
    }

    .color-picker {
        display: none;
        top: 0;
        left: 0;
        z-index: 10;

        &.show {
            display: block;
        }
    }

    .button-color-default {
        text-align: center;
        padding: 0.5rem;
        padding-top: 0;
    }

    .btn-no-round-corner {
        border-radius: 0;
    }

    #editor-description,
    #editor-usage {
        background-color: rgba(255, 255, 255, 1);
    }
}
</style>
